const initialState = {
    masterSettingsLists: [],
    masterStateLists: [],
    masterCityStateWiseLists:[],
    masterUsertypeLists:[],
    masterUserLists:[],
    masterInsuranceList:[],
    masterDesignationList:[],
    masterCheckList:[],
    masterAllCityList:[],
    loadingMaster: false,
    errorsMaster: {}
}

const  masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_SETTINGS_PENDING":
        case "GET_MASTER_STATE_PENDING":
        case "GET_MASTER_CITY_STATE_WISE_PENDING":
        case "GET_MASTER_USERTYPE_USER_WISE_PENDING":
        case "GET_MASTER_USER_USERTYPE_WISE_PENDING":
        case "GET_MASTER_INSURANCE_PENDING":
        case "GET_MASTER_DESIGNATION_PENDING":
        case "GET_MASTER_CHECKLIST_PENDING":
        case "GET_MASTER_ALL_CITY_PENDING":
            return { ...state, loadingMaster: true };

        case "GET_MASTER_SETTINGS_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterSettingsLists: action.payload,
            };

        case "GET_MASTER_STATE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterStateLists: action.payload
        };

        case "GET_MASTER_CITY_STATE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterCityStateWiseLists: action.payload
            };

        case "GET_MASTER_USERTYPE_USER_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUsertypeLists: action.payload
            };

        case "GET_MASTER_USER_USERTYPE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUserLists: action.payload
            };

        case "GET_MASTER_INSURANCE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterInsuranceList: action.payload
            };

        case "GET_MASTER_DESIGNATION_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterDesignationList: action.payload
            };

        case "GET_MASTER_CHECKLIST_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterCheckList: action.payload
            };

        case "GET_MASTER_ALL_CITY_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterAllCityList: action.payload
            };

        case "RESET_MASTER_STATUS":
            return {
                ...state,
                loadingMaster: false,
                errors:{}
            };

        case "GET_MASTER_SETTINGS_REJECTED":
        case "GET_MASTER_STATE_REJECTED":
        case "GET_MASTER_CITY_STATE_WISE_REJECTED":
        case "GET_MASTER_USERTYPE_USER_WISE_REJECTED":
        case "GET_MASTER_INSURANCE_REJECTED":
        case "GET_MASTER_DESIGNATION_REJECTED":
        case "GET_MASTER_CHECKLIST_REJECTED":
        case "GET_MASTER_ALL_CITY_REJECTED":
        case "GET_MASTER_USER_USERTYPE_WISE_REJECTED":
            return {
                ...state,
                loadingMaster: false,
                errorsMaster: action.payload
            };

        default:
            return state;
    }
}

export default masterReducer;