import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getTypeOfClaimListById, saveTypeOfClaimData, updateTypeOfClaimData } from "../../redux/actions/TypeOfClaimActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddTypeOfClaim = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    typeOfClaimReducer: {
      loadingTypeOfClaim,
      errorsTypeOfClaim,
      typeOfClaimLists,
      changedTypeOfClaimStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);

  const [validated, setValidated] = useState(false);
  

  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getTypeOfClaimListById(auth?.token, editId));
    }
  }, [auth, editId]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId){
      dispatch(updateTypeOfClaimData(auth?.token, formData, editId));
    } else {
      dispatch(saveTypeOfClaimData(auth?.token, formData));
    }
  };

  
  useEffect(() => {
    if(editId && typeOfClaimLists && Object.keys(typeOfClaimLists).length > 0) {
      setFormData({
        name: typeOfClaimLists?.name,
      });
    }
  }, [typeOfClaimLists]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  useEffect(() => {
    if (changedTypeOfClaimStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedTypeOfClaimStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_TYPE_OF_CLAIM_STATUS' });
    }
  }, [changedTypeOfClaimStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="md" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && typeOfClaimLists && Object.keys(typeOfClaimLists).length > 0) ? t("Update Claim Type") : t("Add Claim Type")}</Modal.Title>
      </Modal.Header>
        <Modal.Body>
            {errorsTypeOfClaim?.data?.message && (
              <div className="alert alert-danger">
                {errorsTypeOfClaim?.data?.message}
              </div>
            )}
          <Row>
          
            <Col lg={12}>
                <FormInput
                  label={t("Name")}
                  type="text"
                  errors={errorsTypeOfClaim?.data}
                  value={formData.name}
                  name="name"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && typeOfClaimLists && Object.keys(typeOfClaimLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingTypeOfClaim && <Preloader />}
    </Modal>
  );
};

export default AddTypeOfClaim;
