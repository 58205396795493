import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getHospitalListById, saveHospitalData, updateHospitalData } from "../../redux/actions/HospitalActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddHospital = ({ show, onHide, hospitalId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    hospitalReducer: {
      loadingHospital,
      errorsHospital,
      hospitalLists,
      changedHospitalStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);

  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);

  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(hospitalId){
      dispatch(updateHospitalData(auth?.token, formData, hospitalId));
    } else {
      dispatch(saveHospitalData(auth?.token, formData));
    }
  };

  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(hospitalId) {
      dispatch(getHospitalListById(auth?.token, hospitalId));
    }
  }, [auth, hospitalId]);

  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
        const addressComponents = placeData?.address_components;
        const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
        setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  useEffect(() => {
    if(hospitalId && hospitalLists && Object.keys(hospitalLists).length > 0) {
      setFormData({
        hospital_name: hospitalLists?.hospital_name,
        lat: hospitalLists?.lat,
        lng: hospitalLists?.lng,
        address: hospitalLists?.address,
        email: hospitalLists?.email || "",
        mobile_number: hospitalLists?.mobile_number,
        tpa_name: hospitalLists?.tpa_name,
        tpa_number: hospitalLists?.tpa_number,
        state_code: hospitalLists?.hospital_cities?.cities_state?.state_code,
        city_code: hospitalLists?.hospital_cities?.city_code,
      });
    }
  }, [hospitalLists]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
    
  };

  const selectPincodeHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('file',file);
    setFormData((prevFormData) => ({
      ...prevFormData,
      tpa_document: file,
    }));
  };

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["hospital"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    } else {
      setShowSuggestions(false);
    }
  };

  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          hospital_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  useEffect(() => {
    if (changedHospitalStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedHospitalStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    //   dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
    }
  }, [changedHospitalStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Hospital")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsHospital?.data?.message && (
              <div className="alert alert-danger">
                {errorsHospital?.data?.message}
              </div>
            )}
          <Row>
          
            <Col lg={4}>
            <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
            <FormInput
                label={t("Search Hospital Name")}
                type="text"
                value={formData.search_name}
                onChange={handleInputChange}
                ref={inputRef}
                containerClass="mb-3"
              />
              {showSuggestions && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.place_id}
                      className="suggestion-item"
                      onClick={() => handlePlaceSelected(suggestion.place_id)}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              )}
              </LoadScript>
            </Col>
            <Col lg={4}>
                <FormInput
                  label={t("Hospital Name")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.hospital_name}
                  name="hospital_name"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("Email")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.email}
                  name="email"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("Mobile")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.mobile_number}
                  name="mobile_number"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("Latitude")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData?.lat}
                  name="lat"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("Longitude")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData?.lng}
                  name="lng"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("Address")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.address}
                  name="address"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("State")}</Form.Label>
                  <Select
                    options={masterStateLists}
                    values={masterStateLists?.filter(state => state.state_code === formData.state_code)}
                    name="state_code"
                    labelField={"state_name"}
                    valueField={"state_code"}
                    searchBy={"state_name"}
                    loading={loadingMaster}
                    onChange={(selectedData) => {
                      selectHandler2(selectedData[0]?.state_code, "state_code")
                      setStateId(selectedData[0]?.state_code);
                    }}
                  />
                  
                  {errorsHospital?.data?.state_code && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsHospital?.data?.state_code}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("City")}</Form.Label>
                  <Select
                    options={masterCityStateWiseLists}
                    values={masterCityStateWiseLists?.filter(city => city.city_code === formData.city_code)}
                    name="city_code"
                    labelField={"city_name"}
                    valueField={"city_code"}
                    searchBy={"city_name"}
                    loading={loadingMaster}
                    onChange={(selectedData) => {
                      selectHandler2(selectedData[0]?.city_code, "city_code")
                    }}
                  />
                  
                  {errorsHospital?.data?.city_code && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsHospital?.data?.city_code}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("T.P.A. Name")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.tpa_name}
                  name="tpa_name"
                  onChange={inputHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
                <FormInput
                  label={t("T.P.A. Number")}
                  type="text"
                  errors={errorsHospital?.data}
                  value={formData.tpa_number}
                  name="tpa_number"
                  onChange={selectMobileHandler}
                  containerClass={"mb-3"}
                />
              </Col>
              <Col lg={4}>
              <FormInput
                label={t("T.P.A. Document")}
                type="file" // Change the input type to "file"
                errors={errorsHospital?.data}
                value={formData.tpa_document} // Assuming you're storing the file in this field in formData
                name="tpa_document"
                onChange={handleFileChange} // Update the onChange handler
                containerClass={"mb-3"}
              />
              </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                {t("Submit")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingHospital && <Preloader />}
    </Modal>
  );
};

export default AddHospital;
