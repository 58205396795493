import axios from "axios";

export const getLocalityList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LOCALITYLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Locality`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_LOCALITYLIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_LOCALITYLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveLocalityData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_LOCALITY_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Locality`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_LOCALITY_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_LOCALITY_REJECTED", payload: error.response });
    }
  };
  

  export const getLocalityListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LOCALITYLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Locality/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_LOCALITYLISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_LOCALITYLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateLocalityData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_LOCALITY_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Locality/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_LOCALITY_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_LOCALITY_REJECTED", payload: error.response });
  }
};


export const updateLocalityStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_LOCALITY_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Locality-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_LOCALITY_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_LOCALITY_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteLocalityData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_LOCALITY_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Locality/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_LOCALITY_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_LOCALITY_REJECTED", payload: error.response });
  }
};