import axios from "axios";

export const getInsuranceManagerList =
  (token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_INSURANCE_MANAGERLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Insurance-Manager`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_INSURANCE_MANAGERLIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_INSURANCE_MANAGERLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveInsuranceManagerData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_INSURANCE_MANAGER_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Insurance-Manager`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_INSURANCE_MANAGER_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_INSURANCE_MANAGER_REJECTED", payload: error.response });
    }
  };
  

  export const getInsuranceManagerListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_INSURANCE_MANAGERLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Insurance-Manager/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_INSURANCE_MANAGERLISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_INSURANCE_MANAGERLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateInsuranceManagerData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Insurance-Manager/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_REJECTED", payload: error.response });
  }
};


export const updateInsuranceManagerStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Insurance-Manager-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_INSURANCE_MANAGER_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteInsuranceManagerData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_INSURANCE_MANAGER_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Insurance-Manager/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_INSURANCE_MANAGER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_INSURANCE_MANAGER_REJECTED", payload: error.response });
  }
};