import axios from "axios";

export const getMasterSettings = (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_SETTINGS_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Settings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_SETTINGS_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_SETTINGS_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllStateList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_STATE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/State`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_STATE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_STATE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllCityStateWiseList= (token, stateId) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_CITY_STATE_WISE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/City`,
        {
          params: {
            state_id: stateId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterUsertypeUserWiseList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_USERTYPE_USER_WISE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Usertype`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_USERTYPE_USER_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_USERTYPE_USER_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterUserUserTypeWiseList= (token, user_type_id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_USER_USERTYPE_WISE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/User-Usertype-Wise`,
        {
          params: {
            user_type_id: user_type_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_USER_USERTYPE_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_USER_USERTYPE_WISE_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterInsuranceList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_INSURANCE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Insurance-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_INSURANCE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_INSURANCE_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterDesignationList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_DESIGNATION_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Designation-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_DESIGNATION_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_DESIGNATION_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllCheckList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_CHECKLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Checklist`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_CHECKLIST_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_CHECKLIST_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllCityList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_ALL_CITY_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/All-City`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_ALL_CITY_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_ALL_CITY_REJECTED",
        payload: error.response,
      });
    }
  };