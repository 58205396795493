import axios from "axios";

export const getCaseDetailsList =
  ( url ,token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_CASE_DETAILS_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/${url}`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_CASE_DETAILS_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_CASE_DETAILS_LIST_REJECTED",
        payload: error.response,
      });
    }
  };
