import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getGeofenceListById, saveGeofenceData, updateGeofenceData } from "../../redux/actions/GeofenceActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import LeafletSingleMap from "../../Components/LeafletSingleMap";
import { getAddressByLatLng, getAllUsertypeUserList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    lat: 0.0,
    lng: 0.0,
    address: ""
  });
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    geofenceReducer: {
      loadingGeofence,
      errorsGeofence,
      geofenceLists,
      changedGeofenceStatus,
    },
  } = useSelector((state) => state);

  const [validated, setValidated] = useState(false);

  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      console.log('Selected place:', place);
      if (place && place.geometry) {
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: place.formatted_address || ""
        });
      }
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id){
      dispatch(updateGeofenceData(auth?.token, formData, id));
    } else {
      dispatch(saveGeofenceData(auth?.token, formData));
    }
  };

  useEffect(() => {
    if(id) {
      dispatch(getGeofenceListById(auth?.token, id));
    }
  }, [auth, id]);

  useEffect(()=>{
    if(id && geofenceLists && Object.keys(geofenceLists).length > 0) {
      setFormData({
        name: geofenceLists?.name,
        lat: geofenceLists?.lat,
        lng: geofenceLists?.lng,
        address: geofenceLists?.address,
      });
    }
  }, [geofenceLists]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      navigate('/login');
      window.location.reload();
    }
  }, [errors, dispatch, navigate]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsGeofence && Object.keys(errorsGeofence).length > 0)
    {
      if (errorsGeofence?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsGeofence?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
      }
      else if (errorsGeofence?.status === 400)
      {
        //
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
      }
    }
  }, [errorsGeofence, dispatch]);

  useEffect(() => {
    if (changedGeofenceStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedGeofenceStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_GEOFENCES_STATUS' });
      navigate(-1);
    }
  }, [changedGeofenceStatus, dispatch, navigate]);

  return (
    <>
      <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
        <PageTitle
          breadCrumbItems={[
            { label: t("Geofence"), path: "Geofence" },
            { label: t("Add Geofence"), path: "/Add-Geofence", active: true },
          ]}
          title={t("Add Geofence")}
        />
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <div style={{ position: "relative", overflow: "hidden", height: "400px" }}>
                  <LeafletSingleMap
                    mapHeight={"600px"}
                    center={[formData.lat || 0.0, formData.lng || 0.0]}
                    zoom={15}
                    setFormData={setFormData}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Body>
                {errorsGeofence?.data?.message && (
                  <div className="alert alert-danger">
                    {errorsGeofence?.data?.message}
                  </div>
                )}
                <Row>
                  <Col lg={12}>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={handlePlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search for a location"
                      className="form-control mb-3"
                    />
                  </Autocomplete>
                  </Col>
                  <Col lg={12}>
                    <FormInput
                      label={t("Name")}
                      type="text"
                      errors={errorsGeofence?.data}
                      value={formData.name}
                      name="name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormInput
                      label={t("Latitude")}
                      type="text"
                      errors={errorsGeofence?.data}
                      value={formData?.lat}
                      name="lat"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormInput
                      label={t("Longitude")}
                      type="text"
                      errors={errorsGeofence?.data}
                      value={formData?.lng}
                      name="lng"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  <Col lg={12}>
                    <FormInput
                      label={t("Address")}
                      type="text"
                      errors={errorsGeofence?.data}
                      value={formData.address}
                      name="address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                  
                </Row>
                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {loadingGeofence && (<Preloader />)}
      </LoadScript>
    </>
  );
};

export default Dashboard;
