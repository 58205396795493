const auth = localStorage.getItem("asg_insurance_admin")
const initialState = {
    auth: auth ? JSON.parse(auth) : {},
    isAuthenticated: auth ? Object.keys(auth).length ? true : false : false,
    loading: false,
    erors: {}
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_PENDING":
            return { ...state, loading: true }
        case "LOGIN_FULFILLED":
            return { ...state, loading: false, auth: action.payload, isAuthenticated: true }
        case "LOGIN_FAILED":
            //toast.error(action.payload?.message)
            return { ...state, loading: false, errors: action.payload }
        case "LOG_OUT_FULFILLED":
            return { ...state, auth: {}, isAuthenticated: false }
        default:
            return state
    }
}

export default AuthReducer