import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "../../Components/FilterModal";
import { getCaseDetailsList } from "../../redux/actions/CaseDetailsActions";

const CaseDetailsIndex = ({ case_url , title }) => {
  console.log('case_url',case_url);
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [editCaseDetailsId, setEditCaseDetailsId] = useState(null);
  const {
    AuthReducer: { auth,errors,loading },
    caseDetailsReducer: {
      caseDetailsPermissions,
      caseDetailsLists,
      loadingCaseDetails,
      errorsCaseDetails,
      caseDetailsCount,
      changedCaseDetailsStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  const hideModel = () => {
    setShowModal(false);
    setEditCaseDetailsId(null);
    dispatch(getCaseDetailsList(case_url,auth.token, paginate, page, search, sort,filters));
  };
  
  const fetchCaseDetailsList = useCallback(() => {
    if (auth?.token) {
      dispatch(getCaseDetailsList(case_url,auth.token, paginate, page, search, sort,filters));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchCaseDetailsList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  useEffect(()=>{
    console.log('case_url,title',case_url,title);
    dispatch(getCaseDetailsList(case_url,auth.token, paginate, page, search, sort,filters));
  },[case_url,title])

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? caseDetailsCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });

  const handleFilterClick = (key) => {
    if (!filters[key]) {
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
    setModal({ show: true, key, type: filters[key]?.type || "", value: filters[key]?.value || "" });
  };

  const handleModalChange = (field, value) => {
    setModal((prev) => ({ ...prev, [field]: value }));
  };

  const handleApplyFilter = () => {
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: modal.type, value: modal.value },
    }));
    setModal({ show: false, key: "", type: "", value: "" });
  };

  const handleRemoveFilter = (key) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[key];
      return updatedFilters;
    });
    setModal({ show: false, key: "", type: "", value: "" });
  };

  useEffect(() => {
    console.log(filters);
    dispatch(getCaseDetailsList(case_url,auth.token, paginate, page, search, sort,filters));
  },[filters])
  

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: title, path: case_url, active: true },
        ]}
        title={title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        {(Object.keys(filters).length>0) && (
                          <>
                            <Button variant="warning" onClick={() => setFilters({})}>
                              <i className="mdi mdi-filter-off me-1"></i>Clear All Filter
                            </Button>
                          </>
                        )}
                        {(caseDetailsPermissions.add ) && (<>
                          <Button variant="success" onClick={() => {
                            setShowModal(true)
                            setEditCaseDetailsId(null)
                          }} >
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        </>)}
                        {(caseDetailsPermissions.print ) && (<>
                          <Button variant="dark">
                            <i className="mdi mdi-printer me-1"></i>Print
                          </Button>
                        </>)}
                        {(caseDetailsPermissions.excel ) && (<>
                          <Button variant="info">
                            <i className="mdi mdi-file-excel me-1"></i>Excel
                          </Button>
                        </>)}
                        
                        
                        
                        
                        
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                      Claim Number
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("claim_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Policy Number
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("policy_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Hospital Name
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("hospital")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Hospital City
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("hospital_city")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Hospital State
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("hospital_state_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Insurance Company
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("insurance")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Insurance Manager
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("manager")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Type Of Claim
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("claim_type")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Trigger
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("first_trigger")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Case Open Date
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("case_open_date")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Case Open Time
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("case_open_time")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Case Close Date
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("case_close_date")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Case Close Time
                      {caseDetailsPermissions?.filter && (<>
                        <Button variant="link" onClick={() => handleFilterClick("case_close_time")}><FontAwesomeIcon icon={faFilter} /></Button>
                      </>)}
                      </th>
                      <th>
                      Status
                      </th>
                      <th>Created By</th>
                      <th>Registered At<br /></th>
                    </tr>
                  </thead>
                  <tbody>
                  {caseDetailsLists.length === 0 ? (
                    <tr>
                      <td colSpan="17">No data found</td>
                    </tr>
                  ) : (<>
                    {caseDetailsLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.claim_number}</td>
                          <td>{item?.policy_number}</td>
                          <td>{item?.hospitaldCaseDetails?.hospital_name}</td>
                          <td>{item?.hospitaldCaseDetails?.hospital_cities?.city_name}</td>
                          <td>{item?.hospitaldCaseDetails?.hospital_cities?.cities_state?.state_name}</td>
                          <td>{item?.insuranceIdCaseDetails?.name}</td>
                          <td>{item?.ManagerIdCaseDetails?.name}</td>
                          <td>{item?.claimTypeCaseDetails?.name}</td>
                          <td>{item?.first_trigger}</td>
                          <td>{item?.case_open_date}</td>
                          <td>{item?.case_open_time}</td>
                          <td>{item?.case_close_date}</td>
                          <td>{item?.case_close_time}</td>
                          <td>{item?.case_status}</td>
                          <td>{item?.createdByCaseDetails?.name}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(caseDetailsCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(caseDetailsCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <FilterModal
        show={modal.show}
        onHide={() => setModal({ show: false, key: "", type: "", value: "" })}
        modalData={modal}
        onApplyFilter={handleApplyFilter}
        onRemoveFilter={handleRemoveFilter}
        onChange={handleModalChange}
      />
      {loadingCaseDetails && (<Preloader/>)}
    </>
  );
};

export default CaseDetailsIndex;
