const initialSubChecklist = {
    subChecklistPermissions:{},
    subChecklistLists: [],
    subChecklistStatus: [],
    changedSubChecklistStatus : [],
    loadingSubChecklist: false,
    errorsSubChecklist: {}
}

const  subChecklistReducer = (state = initialSubChecklist, action) => {
    switch (action.type) {
        case "GET_SUBCHECKLIST_PENDING":
        case "ADD_SUBCHECKLIST_PENDING":
        case "UPDATE_SUBCHECKLIST_PENDING":
        case "UPDATE_SUBCHECKLIST_STATUS_PENDING":
        case "DELETE_SUBCHECKLIST_PENDING":
        case "GET_SUBCHECKLISTBYID_PENDING":
            return { ...state, loadingSubChecklist: true };

        case "GET_SUBCHECKLIST_FULFILLED":
            return {
                ...state,
                loadingSubChecklist: false,
                subChecklistLists: action.payload,
                subChecklistCount: action.subChecklistCount,
                subChecklistPermissions:action.permissions,
            };

        case "ADD_SUBCHECKLIST_FULFILLED":
            return {
                ...state,
                loadingSubChecklist: false,
                changedSubChecklistStatus: action.payload,
            };

        case "UPDATE_SUBCHECKLIST_FULFILLED":
            return {
                ...state,
                loadingSubChecklist: false,
                changedSubChecklistStatus: action.payload,
            };

        case "UPDATE_SUBCHECKLIST_STATUS_FULFILLED":
                return {
                    ...state,
                    loadingSubChecklist: false,
                    changedSubChecklistStatus: action.payload,
                };

        case "GET_SUBCHECKLISTBYID_FULFILLED":
            return {
                ...state,
                loadingSubChecklist: false,
                subChecklistLists: action.payload
            };

        case "DELETE_SUBCHECKLIST_FULFILLED":
            return {
                ...state,
                loadingSubChecklist: false,
                changedSubChecklistStatus: action.payload
            };

        case "RESET_DELETE_SUBCHECKLIST_STATUS":
            return {
                ...state,
                loadingSubChecklist: false,
                changedSubChecklistStatus: [],
                errorsSubChecklist:{}
            };

        case "GET_SUBCHECKLIST_REJECTED":
        case "ADD_SUBCHECKLIST_REJECTED":
        case "UPDATE_SUBCHECKLIST_REJECTED":
        case "UPDATE_SUBCHECKLIST_STATUS_REJECTED":
        case "DELETE_SUBCHECKLIST_REJECTED":
        case "GET_SUBCHECKLISTBYID_REJECTED":
            return {
                ...state,
                loadingSubChecklist: false,
                errorsSubChecklist: action.payload
            };

        default:
            return state;
    }
}

export default subChecklistReducer;